<template>
  <v-container>
    <loading-spinner
      v-if="(loadingCount > 0) || !widgetData"
    />
    <div
      v-else
    >
      <v-row>
        <v-col
          v-for="(widget, index) in widgets"
          :key="index"
          :lg="widget.columns ?? 4"
          :md="widget.columns ?? 6"
          :cols="12"
        >
          <component
            :is="widget.component"
            v-bind="widget.componentProperties"
            @reload="loadWidgetData"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { usePermissions } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { onMounted, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import CartDisplayWidget from './CartDisplayWidget.vue';
import CourseSessionConfirmationWidget from './CourseSessionConfirmationWidget.vue';
import CourseSessionInviteWidget from './CourseSessionInviteWidget.vue';
import CurrentNeedWidget from './CurrentNeedWidget.vue';
import ExperienceWidget from './ExperienceWidget.vue';
import HeroImageWidget from './HeroImageWidget.vue';
import ShiftWidget from './ShiftWidget.vue';
import WelcomeTextWidget from './WelcomeTextWidget.vue';

const { t } = useI18n();

interface Widget {
  component: object;
  order?: number;
  columns?: number;
  componentProperties?: object;
}

const { handleError } = useErrorStore();
const { can } = usePermissions();
const widgetData = ref<any>(null);
const widgets = shallowRef<Widget[]>([]);
const loadingCount = ref(0);

function generateWidgets() {
  // Resetting widget components
  widgets.value = [];

  widgets.value.push({
    component: HeroImageWidget,
    order: widgets.value.length,
    columns: 6,
    componentProperties: {
      heroImage: widgetData.value.heroImage,
      profileImageStatus: widgetData.value.profileImageStatus,
    },
  });

  // Next course session
  if (widgetData.value.nextCourseSession) {
    widgets.value.push({
      component: CourseSessionInviteWidget,
      order: widgets.value.length,
      columns: 6,
      componentProperties: {
        courseSession: widgetData.value.nextCourseSession,
      },
    });
  }

  // Course session pending confirmation
  if (widgetData.value.courseSessionPendingConfirmation) {
    widgets.value.push({
      component: CourseSessionConfirmationWidget,
      order: widgets.value.length,
      columns: 6,
      componentProperties: {
        courseSession: widgetData.value.courseSessionPendingConfirmation,
      },
    });
  }

  widgets.value.push({
    component: WelcomeTextWidget,
    columns: 12,
    order: widgets.value.length,
    componentProperties: {
      alertMessages: widgetData.value.alertMessages,
      welcomeText: widgetData.value.welcomeText,
    },
  });

  if (can('book-shifts')) {
    // Current shift component
    if (widgetData.value.currentAssignedShift) {
      widgets.value.push({
        component: ShiftWidget,
        order: widgets.value.length,
        componentProperties: {
          caption: t('shift.current'),
          shift: widgetData.value.currentAssignedShift,
        },
      });
    }
    // Next shift component
    if (widgetData.value.nextAssignedShift) {
      widgets.value.push({
        component: ShiftWidget,
        order: widgets.value.length,
        componentProperties: {
          caption: t('shift.my_next'),
          shift: widgetData.value.nextAssignedShift,
        },
      });
    }
    // Current Need component
    widgets.value.push({
      component: CurrentNeedWidget,
      order: widgets.value.length,
    });
  }

  // Cart component
  if (can('access-cart-page')) {
    widgets.value.push({
      component: CartDisplayWidget,
      order: widgets.value.length,
    });
  }

  // Experience component
  if (can('access-experiences-page') && widgetData.value.todaysExperience) {
    widgets.value.push({
      component: ExperienceWidget,
      order: widgets.value.length,
      componentProperties: {
        experience: widgetData.value.todaysExperience,
      },
    });
  }
}

async function loadWidgetData() {
  loadingCount.value += 1;
  try {
    const response = await axios.get('/api/widgetdata');
    widgetData.value = response.data.data;
    generateWidgets();
  }
  catch (error) {
    handleError(error, t('errors.cannot_load_widgets'));
  }
  finally {
    loadingCount.value -= 1;
  }
}

onMounted(() => {
  loadWidgetData();
});
</script>
