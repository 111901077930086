<template>
  <loading-spinner v-if="(loadingCount > 0) || !teams" />
  <div
    v-else
  >
    <div
      v-for="(team) in teams"
      :key="team.id"
    >
      <v-divider class="mt-2" />
      <div class="d-flex flex-row justify-space-between align-center">
        <div class="text-h5 mt-1">
          {{ team.name }}
        </div>
        <div class="my-1">
          <team-edition-dialog
            v-if="can('manage-teams') || (authUser.id == team.overseer?.id)"
            mode="edit"
            :model-id="team.id"
          />
        </div>
      </div>
      <v-divider class="mt-1" />

      <div>
        <div
          v-if="((!affiliatedOnly) || team.description_visible) && team.description"
          class="mt-2"
        >
          <div
            class="text-body-1 tiptap"
            v-html="team.description"
          />
        </div>

        <v-row>
          <v-col
            v-if="team.overseer"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <member-card
              class="mt-3"
              :title="t('global.overseer')"
              :user="team.overseer"
              fill-height
            />
          </v-col>

          <v-col
            v-for="(assistant) in team.members?.filter((a) => {
              /* @ts-ignore */
              return a.pivot.role == 'assistant'
            })"
            :key="assistant.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <member-card
              class="mt-3"
              :title="t('global.assistant')"
              :user="assistant"
              fill-height
            />
          </v-col>
          <v-col
            v-for="(member) in team.members?.filter((a) => {
              /* @ts-ignore */
              return a.pivot.role != 'assistant'
            })"
            :key="member.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <member-card
              class="mt-3"
              :title="t('member.title')"
              :user="member"
              heading-color="grey"
              fill-height
            />
          </v-col>
        </v-row>
      </div>
      <v-divider />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuth, usePermissions } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MemberCard from './MemberCard.vue';
import TeamEditionDialog from './TeamEditionDialog.vue';

const props = withDefaults(defineProps<{
  affiliatedOnly?: boolean;
}>(), {
  affiliatedOnly: () => false,
});

const { t } = useI18n();

const { authUser } = useAuth();
const { can } = usePermissions();
const { handleError } = useErrorStore();

const teams = ref<Array<App.Models.Team>>([]);
const loadingCount = ref(0);

async function loadTeamsData() {
  loadingCount.value += 1;
  let apiUrl = '/api/teams';
  if (props.affiliatedOnly) {
    apiUrl = '/api/teams/affiliated';
  }
  try {
    const response = await axios.get(apiUrl);
    teams.value = response.data.data;
  }
  catch (error) {
    handleError(error, t('team.cannot_load'));
  }
  finally {
    loadingCount.value -= 1;
  }
}

loadTeamsData();
</script>
