<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/managing-shifts/current-needs"
    />
    <v-tabs
      v-model="tab"
      class="mb-4"
    >
      <smpw-tab
        value="shift"
        :active="tab === 'shift'"
        :text="t('views.shift_view')"
      />
      <smpw-tab
        v-if="can('access-current-needs-overview')"
        value="detailed"
        :active="tab === 'detailed'"
        :text="t('views.detailed_week_view')"
      />
    </v-tabs>

    <v-window
      v-model="tab"
      :touch="false"
    >
      <v-window-item
        value="shift"
      >
        <v-banner>
          <scheduler-headings
            :show-week-view="false"
            :min="calendarMinDate"
            :max="calendarMaxDate"
            :disable-date-input="false"
          />
        </v-banner>

        <v-container>
          <v-row>
            <v-col>
              <v-chip
                color="primary"
                class="me-1 mt-1"
                prepend-icon="fas fa-user-tie"
                :text="captainLabel"
              />
              <v-chip
                color="success"
                class="me-1 mt-1"
                prepend-icon="fas fa-user"
                :text="t('smpw.publisher', 2)"
              />
              <v-chip
                color="warning"
                class="me-1 mt-1"
                prepend-icon="fas fa-user"
                :text="t('global.brother', 2)"
              />
            </v-col>
          </v-row>
        </v-container>

        <loading-spinner v-if="isLoading" />

        <div v-if="!isLoading">
          <v-table v-if="currentNeeds.length > 0">
            <thead>
              <tr>
                <th>{{ t('global.location') }}</th>
                <th>{{ t('global.shift') }}</th>
                <th>{{ t('global.need') }}</th>
                <th>{{ t('global.action') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(currentNeed, index) in currentNeedsWithSlotBookingStatus"
                :key="index"
                :class="`status-${currentNeed.status}`"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <div class="ms-3">
                      <p class="fw-bold mb-0 text-break">
                        {{ currentNeed.location_name }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="fw-normal mb-1">
                    {{ currentNeed.shift_name }}
                  </p>
                  <p class="text-muted mb-0">
                    {{
                      `${toShortTime(
                        currentNeed.shift_start
                      )} - ${calculateAndFormatEndTime(
                        currentNeed.shift_start,
                        currentNeed.shift_duration
                      )}`
                    }}
                  </p>
                </td>
                <td>
                  <div v-if="currentNeed.missing_publisher > 0">
                    <v-chip
                      v-if="!mobile"
                      color="success"
                      class="my-1 me-1"
                      prepend-icon="fas fa-user"
                      :text="`${t('smpw.publisher', 2)} (${currentNeed.missing_publisher})`"
                    />
                    <v-btn
                      v-else
                      v-tooltip="t('smpw.publisher', 2)"
                      class="my-1 me-1"
                      icon="fas fa-user"
                      color="success"
                    />
                  </div>
                  <div v-if="currentNeed.need_captain">
                    <v-chip
                      v-if="!mobile"
                      color="primary"
                      class="my-1 me-1"
                      prepend-icon="fas fa-user-tie"
                      :text="captainLabel"
                    />
                    <v-btn
                      v-else
                      v-tooltip="captainLabel"
                      class="my-1 me-1"
                      icon="fas fa-user-tie"
                      color="primary"
                    />
                  </div>
                  <div v-if="currentNeed.missing_brothers > 0">
                    <v-chip
                      v-if="!mobile"
                      color="warning"
                      class="my-1 mb-2"
                      prepend-icon="fas fa-user-tie"
                      :text="`${t('global.brother', currentNeed.missing_brothers)} (${currentNeed.missing_brothers})`"
                    />
                    <v-btn
                      v-else
                      v-tooltip="t('global.brother', currentNeed.missing_brothers)"
                      class="my-1 mb-2"
                      icon="fas fa-user"
                      color="warning"
                    />
                  </div>
                </td>
                <td>
                  <v-chip
                    v-if="currentNeed.status === 'assigned'"
                    color="success"
                    :text="t('states.assigned')"
                  />
                  <v-chip
                    v-else-if="currentNeed.status === 'available'"
                    color="primary"
                    :text="t('states.pending')"
                  />
                  <v-chip
                    v-else-if="currentNeed.status === 'limit_reached_day' || currentNeed.status === 'limit_reached_week'"
                    color="primary"
                    :text="t('shift.limit_reached')"
                  />
                  <v-chip
                    v-else-if="currentNeed.status === 'assigned_elsewhere'"
                    color="primary"
                    :text="t('shift.assigned_elsewhere')"
                  />
                  <v-btn
                    v-else
                    color="primary"
                    prepend-icon="fas fa-plus"
                    :text="mobile ? t('smpw.submit') : t('smpw.submit_availability')"
                    @click="submitAvailability(currentNeed)"
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-alert
            v-else
            class="text-font"
            :text="t('shift.all_shifts_covered')"
          />
          <availability-dialog
            ref="avd"
            :user="authUser"
            :pre-selected-slots="selectedSlot ? [selectedSlot] : []"
          />
        </div>
      </v-window-item>

      <v-window-item
        v-if="can('access-current-needs-overview')"
        value="detailed"
      >
        <shift-scheduler
          :with-breadcrumbs="false"
          :sticky="false"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup lang="ts">
import AvailabilityDialog from '@/components/dialogs/AvailabilityDialog.vue';
import {
  useAuth,
  useDate,
  usePermissions,
  useSettings,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import { useScheduleStore } from '@/stores/schedule';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import {
  computed,
  defineAsyncComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import SchedulerHeadings from './scheduler/SchedulerHeadings.vue';

const ShiftScheduler = defineAsyncComponent(() => import('@/components/scheduler/ShiftScheduler.vue'));
const { t } = useI18n();
const breadcrumbs = computed(() => [{ title: t('shift.current_needs') }]);

const scheduleStore = useScheduleStore();

const {
  selectedDate,
  weekView,
} = storeToRefs(scheduleStore);
const { mobile } = useDisplay();
const { handleError } = useErrorStore();
const { getSetting } = useSettings();
const { authUser } = useAuth();
const { can } = usePermissions();
const {
  maxCurrentNeedsDate,
  toUniversalDate,
  toShortTime,
  calculateAndFormatEndTime,
  getAsDate,
} = useDate();

const tab = ref('shift');
const calendarMinDate = toUniversalDate(getAsDate());
const calendarMaxDate = toUniversalDate(maxCurrentNeedsDate());

const currentNeeds = ref<CurrentNeed[]>([]);
const requestsInFlight = ref(0);
const selectedSlot = ref<PreselectedSlot>();
const shiftData = ref<App.ApiResources.PublisherShiftResource[]>([]);
const avd = ref<typeof AvailabilityDialog>();
const captainLabel = getSetting('captain_label');
const isLoading = computed(() => requestsInFlight.value > 0);

const currentNeedsWithSlotBookingStatus = computed(() => currentNeeds.value.map((need) => {
  const publisherShift = shiftData.value?.find((s) => s.shift_id === need.shift_id);
  const status = publisherShift?.status ?? 'none';
  return { ...need, status };
}));

async function loadShiftDataForAuthenticatedUser() {
  requestsInFlight.value += 1;
  try {
    const response = await axios
      .get(`/api/users/${authUser.id}/shifts`, {
        params: {
          date: selectedDate.value,
          side_effect: false,
        },
      });
    shiftData.value = response.data.shifts;
  }
  catch (error) {
    handleError(error, t('errors.cannot_load'));
  }
  finally {
    requestsInFlight.value -= 1;
  }
}

function submitAvailability(currentNeed) {
  selectedSlot.value = {
    shift_time_band_id: currentNeed.shift_time_band_id,
    location_id: currentNeed.location_id,
  };
  avd.value?.updateAvailability(
    getAsDate(`${currentNeed.date}T00:00`),
    loadShiftDataForAuthenticatedUser,
  );
}

async function loadShiftCurrentNeeds() {
  requestsInFlight.value += 1;
  try {
    const response = await axios.get('/api/shifts/currentNeeds', {
      params: {
        date: selectedDate.value,
      },
    });
    currentNeeds.value = response.data.data;
  }
  catch (error) {
    handleError(error, t('errors.cannot_load'));
  }
  finally {
    requestsInFlight.value -= 1;
  }
}

onMounted(() => {
  weekView.value = true;
  loadShiftCurrentNeeds();
  loadShiftDataForAuthenticatedUser();
});

watch(selectedDate, async () => {
  await loadShiftCurrentNeeds();
  await loadShiftDataForAuthenticatedUser();
});
</script>

<style scoped>
.status-assigned,
.status-available {
  opacity: 0.6;
}
</style>
